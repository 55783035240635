<template>
  <v-row justify="center">
    <v-dialog v-model="shower" scrollable persistent max-width="900px">
      <!-- ตารางพนักงาน -->
      <v-card v-if="checkshowtable === 'e'">
        <v-card-title v-if="sharedrive === true">{{ $t("admin.employeeinshare") }}</v-card-title>
        <v-card-title v-else>{{ $t("admin.employeeoutshare") }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text v-if="loaddataprogress === true">
          <!-- <v-flex lg12 xs12 class="ma-2 ml-4 mr-4">
            <div class="text-center">
              <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular>
            </div>
          </v-flex> -->
          <div class="text-center">
            <!-- <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular> -->
              <v-skeleton-loader                         
                class="mx-auto"
                type="table"
              ></v-skeleton-loader>
        </div>
        </v-card-text>
        <v-card-text v-else>
          <v-list dense v-if="total_person > 0">
            <v-list-item v-for="item in dataperson" :key="item.title" dense>
              <v-list-item-avatar v-text="item.list"></v-list-item-avatar>
              <v-list-item-content class="pt-0 pb-0 ">
                <v-list-item-title v-if="$t('default') === 'th'"
                  ><v-row hide-detail class="pt-0 pb-0 pl-0 pr-0"
                    ><v-col hide-detail class="pt-0 pb-0 ">{{ item.name_th }}</v-col
                    ><v-col hide-detail class="pt-0 pb-0 "
                      ><p class="text--secondary text-right mb-0">{{ item.used_storage }}</p></v-col
                    ></v-row
                  ></v-list-item-title
                >
                <v-list-item-title v-else
                  ><v-row hide-detail class="pt-0 pb-0 pl-0 pr-0"
                    ><v-col hide-detail class="pt-0 pb-0 ">{{ item.name_eng }}</v-col
                    ><v-col hide-detail class="pt-0 pb-0 "
                      ><p class="text--secondary text-right mb-0">{{ item.used_storage }}</p></v-col
                    ></v-row
                  ></v-list-item-title
                >
                <v-list-item-subtitle>
                  <!-- <v-progress-linear :color="color.theme" height="4" :value="item.percent_storage"></v-progress-linear> -->
                  <v-progress-linear
                    v-if="item.percent_storage <= 70"
                    :color="color.theme"
                    height="12"
                    :value="item.percent_storage"
                    ><strong class="caption font-weight-light">{{ Math.round(item.percent_storage) }}%</strong></v-progress-linear
                  >
                  <v-progress-linear
                    v-else-if="item.percent_storage <= 90 && item.percent_storage > 70"
                    color="amber"
                    height="12"
                    :value="item.percent_storage"
                    ><strong class="caption font-weight-light">{{ Math.round(item.percent_storage) }}%</strong></v-progress-linear
                  >
                  <v-progress-linear v-else-if="item.percent_storage > 90" color="red" height="12" :value="item.percent_storage"
                    ><strong class="caption font-weight-light">{{ Math.round(item.percent_storage) }}%</strong></v-progress-linear
                  >
                </v-list-item-subtitle>
                <!-- <v-list-item-subtitle v-text="item.used_storage"></v-list-item-subtitle> -->
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list dense v-else-if="total_person === 0" class=" elevation-0">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-center" v-text="$t('tablefile.empty')"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-layout row wrap justify-center>
            <v-flex xs11 lg11 v-if="pageCountPerson > 0">
              <v-pagination
                :total-visible="10"
                v-model="pageP"
                :length="pageCountPerson || 0"
                :color="color.theme"
                @input="changepage_person()"
              ></v-pagination>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" dark @click="$emit('closeseeall'), cleardata()">{{ $t("changeName.close") }}</v-btn>
        </v-card-actions>
      </v-card>
      <!-- ตารางแผนก -->
      <v-card v-if="checkshowtable === 'd'">
        <v-card-title>{{ $t("admin.alldepartment") }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text v-if="loaddataprogress === true">
          <v-flex lg12 xs12 class="ma-2 ml-4 mr-4">
            <div class="text-center">
              <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular>
            </div>
          </v-flex>
        </v-card-text>
        <v-card-text v-else>
          <v-list dense v-if="datadepartment.length > 0">
            <v-list-item v-for="item in paginatedDepartment" :key="item.title" dense>
              <v-list-item-avatar v-text="item.list"></v-list-item-avatar>
              <v-list-item-content class="pt-0 pb-0 ">
                <v-list-item-title
                  ><v-row hide-detail class="pt-0 pb-0 pl-0 pr-0"
                    ><v-col hide-detail class="pt-0 pb-0 ">{{ item.name_department }}</v-col
                    ><v-col hide-detail class="pt-0 pb-0 "
                      ><p class="text--secondary text-right mb-0">{{ item.used_storage }}</p></v-col
                    ></v-row
                  ></v-list-item-title
                >
                <v-list-item-subtitle>
                  <!-- <v-progress-linear :color="color.theme" height="4" :value="item.percent_storage"></v-progress-linear> -->
                  <v-progress-linear
                    v-if="item.percent_storage <= 70"
                    :color="color.theme"
                    height="12"
                    :value="item.percent_storage"
                    ><strong class="caption font-weight-light">{{ Math.round(item.percent_storage) }}%</strong></v-progress-linear
                  >
                  <v-progress-linear
                    v-else-if="item.percent_storage <= 90 && item.percent_storage > 70"
                    color="amber"
                    height="12"
                    :value="item.percent_storage"
                    ><strong class="caption font-weight-light">{{ Math.round(item.percent_storage) }}%</strong></v-progress-linear
                  >
                  <v-progress-linear v-else-if="item.percent_storage > 90" color="red" height="12" :value="item.percent_storage"
                    ><strong class="caption font-weight-light">{{ Math.round(item.percent_storage) }}%</strong></v-progress-linear
                  >
                </v-list-item-subtitle>
                <!-- <v-list-item-subtitle v-text="item.used_storage"></v-list-item-subtitle> -->
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-layout row wrap justify-center>
            <v-flex xs11 lg11 v-if="pageCountDepartment > 0">
              <v-pagination
                :total-visible="10"
                v-model="pageD"
                :length="pageCountDepartment || 0"
                :color="color.theme"
              ></v-pagination>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" dark @click="$emit('closeseeall')">{{ $t("changeName.close") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import sizeDataConverter from "@/globalFunctions/sizeDataConverter";
export default {
  props: ["show", "checkshowtable", "drive_use", "sharedrive"],
  data: function() {
    return {
      tab: null,
      dataperson: [],
      datadepartment: [],
      loaddataprogress: true,
      pageP: 1,
      sizeP: 20,
      pageD: 1,
      sizeD: 20,
      total_person: 0,
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    shower: {
      get() {
        if (this.show === true) {
          
        }
        return this.show;
      },
      set(value) {
        if (!value) {
          this.$emit("closeseeall");
        }
      },
    },
    pageCountPerson: {
      get() {
        console.log(this.dataperson.length);
        let l = this.total_person;
        let s = this.sizeP;
        return Math.ceil(l / s);
      },
      set(newName) {
        return newName;
      },
    },
    pageCountDepartment: {
      get() {
        console.log(this.datadepartment.length,this.sizeD,Math.ceil(l / s));
        let l = this.datadepartment.length;
        let s = this.sizeD;
        return Math.ceil(l / s);
      },
      set(newName) {
        return newName;
      },
    },
    paginatedDepartment() {
      const start = (this.pageD - 1) * this.sizeD;
      const end = start + this.sizeD;
      return this.datadepartment.slice(start, start + this.sizeD);
    },
  },
        watch:{
      show:function(val){
        if( val === true){
          console.log(this.checkshowtable);
          if (this.checkshowtable === "e") {
            this.fn_getdatapersonal();
            console.log(this.dataperson);
          } else {
            this.fn_getdatadepartment();
          }
          console.log(this.sharedrive);
        }else{
        
        }
      }
    },
  methods: {
    cleardata() {
      this.dataperson = [];
      this.datadepartment = [];
      this.total_person = 0;
      this.pageP = 1;
      this.pageD = 1;
    },
    changepage_person() {
      console.log("เปลี่ยนหน้า");
      console.log(this.pageP,this.sizeP);
      this.fn_getdatapersonal();
    },
   async fn_getdatapersonal() {
      this.dataperson = [];
      this.loaddataprogress = true;
     
      let cal_limit = this.sizeP;
      let cal_offset = this.sizeP * (this.pageP - 1);
       console.log(cal_offset,this.sizeP,this.pageP,this.sizeP * (this.pageP - 1));
       
      var payload = {
        account_admin: this.dataAccountId,
        business_id: this.dataAccountActive.business_info.business_id,
        limit: cal_limit.toString(),
        offset: cal_offset.toString(),
        type:"employee",
        topFive:""
      };
      console.log(payload);
      let auth = await gbfGenerate.generateToken();
      this.axios
        .post(process.env.VUE_APP_SERVICE_ADMIN + "/api/admin/get_employees_and_department_for_dashborad", payload,{headers: { Authorization: auth.code },})
        .then((response) => {
          console.log(response);
          if (response.data.status === "OK") {
            this.loaddataprogress = false;
            console.log("sharedrive",this.sharedrive);
            console.log(response.data.result.drive_in_business.list_data_in_drive_business.length);
            if(this.sharedrive === true){
              this.total_person = response.data.result.drive_in_business.count_personnel_in_business;
            for (let i = 0; i < response.data.result.drive_in_business.list_data_in_drive_business.length; i++) {
              let data = {};
              data["list"] = i + 1;
              data["account_id"] = response.data.result.drive_in_business.list_data_in_drive_business[i]["account_id"];
              data["name_eng"] = response.data.result.drive_in_business.list_data_in_drive_business[i]["fullname_eng"];
              data["name_th"] = response.data.result.drive_in_business.list_data_in_drive_business[i]["fullname_th"];
              data["percent_storage"] = this.calPercent(response.data.result.drive_in_business.list_data_in_drive_business[i]["used_storage_in_business_byte"], this.drive_use);
              data["used_storage"] = sizeDataConverter.convertStorageToKbMbGb(response.data.result.drive_in_business.list_data_in_drive_business[i]["used_storage_in_business_byte"]);
              data["user_id"] = response.data.result.drive_in_business.list_data_in_drive_business[i]["user_id"];
              this.dataperson.push(data);
            }
            console.log("i",this.dataperson);
            }else{
               this.total_person = response.data.result.drive_out_business.count_personnel_out_business;
            for (let i = 0; i < response.data.result.drive_out_business.list_data_out_drive_business.length; i++) {
              let data = {};
              data["list"] = i + 1;
              data["account_id"] = response.data.result.drive_out_business.list_data_out_drive_business[i]["account_id"];
              data["name_eng"] = response.data.result.drive_out_business.list_data_out_drive_business[i]["fullname_eng"];
              data["name_th"] = response.data.result.drive_out_business.list_data_out_drive_business[i]["fullname_th"];
              data["percent_storage"] = this.calPercent(response.data.result.drive_out_business.list_data_out_drive_business[i]["used_storage_in_business_byte"], this.drive_use);
              data["used_storage"] = sizeDataConverter.convertStorageToKbMbGb(response.data.result.drive_out_business.list_data_out_drive_business[i]["used_storage_in_business_byte"]);
              data["user_id"] = response.data.result.drive_out_business.list_data_out_drive_business[i]["user_id"];
              this.dataperson.push(data);
            }
            console.log("o",this.dataperson);
            }
          }else{
            this.loaddataprogress = false;
             Toast.fire({
              icon: "error",
              title: response.data.errorCode + ': ' + response.data.errorMessage,
            });
          }
          
        });
      //
    },
   async fn_getdatadepartment() {
      this.datadepartment = [];
      this.loaddataprogress = true;
      var payload = {
        account_admin: this.dataAccountId,
        business_id: this.dataAccountActive.business_info.business_id,
      };
      let auth = await gbfGenerate.generateToken();
      this.axios
        .post(process.env.VUE_APP_SERVICE_ADMIN + "/api/admin/dashboard_admin_business/department_all", payload,{headers: { Authorization: auth.code },})
        .then((response) => {
          if (response.data.status === "OK") {
            this.loaddataprogress = false;
            for (let i = 0; i < response.data.result.length; i++) {
              let data = {};
              data["list"] = i + 1;
              data["my_folder_id"] = response.data.result[i]["my_folder_id"];
              data["name_department"] = response.data.result[i]["department_name"];          
              data["percent_storage"] = this.calPercent(response.data.result[i]["used_storage"], this.drive_use);
              data["used_storage"] = sizeDataConverter.convertStorageToKbMbGb(response.data.result[i]["used_storage"]);         
              this.datadepartment.push(data);
            }
           console.log(this.datadepartment);
          }else{
            this.loaddataprogress = false;
             Toast.fire({
              icon: "error",
              title: response.data.errorCode + ': ' + response.data.errorMessage,
            });
          }
          });
    },
    calPercent(scrap, part) {
      console.log(scrap, part);
      let result;
      if (scrap === 0 || part === 0) {
        result = 0;
      } else {
        result = (scrap / part) * 100;
      }

      return result;
    },
  },
};
</script>
<style></style>
